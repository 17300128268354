import { useBlogContext } from 'context/BlogContext';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useRemoveScrollTop = () => {
  const { asPath } = useRouter();
  const { dispatch } = useBlogContext();

  useEffect(() => {
    if (!asPath.includes('blog')) {
      dispatch({ type: 'change', newState: { prevScrollTop: 0 } });
    }
  }, [asPath, dispatch]);
};
