import { ContactButton, ScrollButton } from 'components/Global/index';
import { ContactPopup } from 'components/Popup/index';
import { useContactModal } from 'hooks/useContactModal';
import { scrollTo } from 'seamless-scroll-polyfill';
import styled from 'styled-components';

const FloatingController = ({ isVisible }: { isVisible: boolean }) => {
  const {
    isContactModalOpen,
    handleCloseContactModal,
    handleOpenContactModal,
  } = useContactModal();
  const handleClickScrollBtn = () => {
    if (!isVisible) scrollTo(window, { top: 0, behavior: 'smooth' });
    else {
      const $aboutMainBanner = document.querySelector(
        '.main-section-container'
      );
      if ($aboutMainBanner) {
        const scrollValue = $aboutMainBanner.getBoundingClientRect().height;
        scrollTo(window, { top: scrollValue, behavior: 'smooth' });
      }
    }
  };

  return (
    <FloatingControllerContainer>
      <ScrollButton
        isOpenContact={isContactModalOpen}
        isScrolled={!isVisible}
        onClick={handleClickScrollBtn}
      />
      <ContactButton onClick={handleOpenContactModal} />
      {isContactModalOpen && (
        <ContactPopup handleCloseContact={handleCloseContactModal} />
      )}
    </FloatingControllerContainer>
  );
};

const FloatingControllerContainer = styled.div`
  .io-target {
    position: absolute;
    top: 0;
    height: 60px;
    width: 100%;
    z-index: -1;
  }
  position: fixed;
  z-index: 99;
  bottom: 56px;
  text-align: right;
  right: 32px;
  @media screen and (max-width: 904px) and (min-width: 600px) {
    bottom: 32px;
    right: 32px;
  }
  @media screen and (max-width: 599px) {
    bottom: 32px;
    right: 16px;
  }
`;
export default FloatingController;
