import { useEffect } from 'react';

export const setScreenSize = () => {
  let vh = (window.innerHeight > 640 ? window.innerHeight : 640) * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const useSetScreenSize = () => {
  useEffect(() => {
    setScreenSize();

    window.addEventListener('resize', setScreenSize);
    window.addEventListener('orientationchange', setScreenSize);

    return () => {
      window.removeEventListener('resize', setScreenSize);
      window.removeEventListener('orientationchange', setScreenSize);
    };
  }, []);
};
