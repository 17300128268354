import { useEffect } from 'react';

export const useDisablePinchZoom = () => {
  useEffect(() => {
    const handleDisablePinchZoom = (e: TouchEvent) => {
      if (e.touches.length > 1 || e.targetTouches.length > 1) {
        e.preventDefault();
      }
    };
    const handleDisablePinchZoomSafari = (e: Event) => e.preventDefault();

    window.addEventListener('touchstart', handleDisablePinchZoom);
    document.documentElement.addEventListener(
      'touchstart',
      handleDisablePinchZoom
    );
    document.addEventListener('gesturestart', handleDisablePinchZoomSafari);
  }, []);
};
