import en from 'locale/en';
import ko from 'locale/ko';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import NewHeader from 'components/NewHeader';
import Footer from 'components/Footer/Footer';
import { Buttons } from 'components/Global/ProductButton';
import {
  MainBanner,
  CoreTech,
  ProductTemplate,
  CareerBanner,
} from 'components/Home/index';
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoomIn';
import { useSetScreenSize } from 'hooks/useSetScreenSize';
import Head from 'next/head';
import { BypassBlock } from 'components/Global/index';
import FloatingController from 'components/Common/FloatingController';
import { Contact } from 'components/Popup/index';
import { GRAYBG } from 'global-styles';
import { IntersectionOptions, useInView } from 'react-intersection-observer';
import { useRemoveScrollTop } from 'hooks/useRemoveScrollTop';

const option: IntersectionOptions = {
  threshold: 0.15,
  fallbackInView: true,
};

const HomePage = () => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;
  const [$fakeHeaderRef, isFakeHeaderVisible, fakeHeaderEntry] =
    useInView(option);
  const [$mainBannerRef, isMainBannerVisible] = useInView(option);

  useDisablePinchZoom();
  useSetScreenSize();
  useRemoveScrollTop();

  return (
    <HomepageContainer className="home">
      <Head>
        <title>{t.metaTag.title}</title>
        <meta name="title" content={t.metaTag.title} key="title" />
        <meta
          name="description"
          content={t.metaTag.description}
          key="description"
        />
        <meta name="keywords" content={t.metaTag.keywords} key="keywords" />
        <meta property="og:type" content={t.metaTag.og_type} key="og:type" />
        <meta property="og:title" content={t.metaTag.og_title} key="og:title" />
        <meta
          property="og:description"
          content={t.metaTag.og_description}
          key="og:description"
        />
        <meta property="og:url" content={t.metaTag.og_url} key="og:url" />
        <meta
          property="twitter:card"
          content={t.metaTag.twitter_card}
          key="twitter:card"
        />
        <meta
          property="twitter:domain"
          content={t.metaTag.twitter_domain}
          key="twitter:domain"
        />
        <meta
          property="twitter:url"
          content="https://blue-dot.io"
          key="twitter:url"
        />
        <meta
          property="twitter:title"
          content={t.metaTag.twitter_title}
          key="twitter:title"
        />
        <meta
          property="twitter:description"
          content={t.metaTag.twitter_description}
          key="twitter:description"
        />
      </Head>

      {/* bypass block */}
      <BypassBlock contentSectionId="#main-content1" />
      <NewHeader
        isScrolled={!isFakeHeaderVisible && !!fakeHeaderEntry?.target}
      />

      <div className="io-target" ref={$fakeHeaderRef} />
      <div className="track">
        <MainBanner $mainBannerRef={$mainBannerRef} />
        <CoreTech />
        <section className="product-list-container">
          {t.productTemplateList.map((product, i: number) => (
            <ProductTemplate
              buttons={Buttons[i]}
              key={product.productName}
              imgPath={product.imgPath}
              productName={product.productName}
              productPhrase={product.productPhrase}
              productDescription={product.productDescription}
              productFeatureList={product.productFeatureList}
              reverseLayout={product.reverseLayout}
              className="product-template"
            />
          ))}
        </section>

        {/* <Button /> */}
        <Contact />
        <CareerBanner />
        <Footer />
      </div>

      <FloatingController isVisible={isMainBannerVisible} />
      {/* <PopupArea /> */}
    </HomepageContainer>
  );
};

export default HomePage;

const HomepageContainer = styled.div`
  min-height: 640px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
  width: 100%;
  position: relative;
  z-index: 99;
  ${({ theme }) => theme.removeScroll};

  .io-target {
    position: absolute;
    top: 0;
    height: 72px;
    width: 100%;
    z-index: -1;
    @media screen and (max-width: 1439px) {
      height: 64px;
    }
    @media screen and (max-width: 1239px) {
      height: 60px;
    }
    @media screen and (max-width: 599px) {
      height: 56px;
    }
  }
  .product-list-container {
    background: ${GRAYBG};
    z-index: 1;
    @media screen and (min-width: 1440px) {
      padding: 202px 0;
    }
    @media screen and (min-width: 1240px) and (max-width: 1439px) {
      padding: 146px 0;
    }
    @media screen and (min-width: 905px) and (max-width: 1239px) {
      padding: 146px 0;
    }
    @media screen and (max-width: 904px) {
      padding: 96px 0;
    }
  }
  .floating {
    position: fixed;
    z-index: 99;
    bottom: 56px;
    text-align: right;
    right: 32px;
    @media screen and (max-width: 904px) and (min-width: 600px) {
      bottom: 32px;
      right: 32px;
    }
    @media screen and (max-width: 599px) {
      bottom: 32px;
      right: 16px;
    }
  }
`;
